
export default {
  name: 'ReportComponent',
  props: {
    result: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    reportReason: '',
  }),
  computed: {
    response() {
      return this.result?.response?.body
    },
  },
  methods: {
    onCancel() {
      this.$emit('cancel')
    },
    onConfirm() {
      this.$emit('confirm', this.reportReason)
    },
  },
}
